import React from "react";
import { useIntl } from "gatsby-plugin-intl";

import AppLayout from "../../../components/shared/AppLayout";
import hotaiLogo from "../../../assets/images/terms-and-conditions/hotai-logo.png";

const PageTermsAndConditions08Lite = () => {
    const intl = useIntl();
    const messages = intl.messages;

    return (
        <AppLayout hasHeader={true} isBackBtn={true} hasFooter={true}>
            <div className="app-page page-terms-and-conditions">

                <div className="terms-and-conditions">

                    <div className="container">

                        <div className="terms-and-conditions__wrapper">
                            <img className="terms-and-conditions__hotai-logo img-fluid" src={hotaiLogo} alt="" />
                            <p>
                                <b style={{ color: "red" }}>免費保戶服務電話：0800-880550
                                <br/>資訊公開查詢: {messages["common.website.url"]} 免費申訴電話:0800-501888消費者可至本公司總、分支機構、網址查閱或索取書面資訊公開說明文件。
                                <br/>本商品經本公司合格簽署人員檢視其內容業已符合保險精算原則及保險法 令，惟為確保權益，基於保險業與消費者衡平對等原則，消費者仍應詳加 閱讀保險單條款與相關文件，審慎選擇保險商品。本商品如有虛偽不實或 違法情事，應由本公司及負責人依法負責。</b>
                            </p>
                            <p style={{ textAlign: "center" }}>
                                <b>和泰產物行動裝置保險<br/>(Samsung Care+ Lite 一次交付型適用)</b>
                                <br/><span style={{ color: "red" }}>(主要給付項目：承保行動裝置之原機維修或置換)</span></p>
                            <p style={{ textAlign: "right", color: "red", textDecoration: "underline" }}>111.3.14(111)和泰產商品字第 125835 號函備查</p>
                            <p>
                                <b>第一條 保險契約之構成與解釋</b><br/>
                                <br/>本保險契約所載之條款及其他附加條款、批單或批註及與 本保險契約有關之文件，均為本保險契約之構成部分。本保險契約之解釋，應探求契約當事人之真意，不得拘泥於所用之文字；如有疑義時，以作有利於被保險人之解釋 為原則。
                            </p>
                            <p style={{ color: "red" }}>
                                <b>第二條 用詞定義</b>
                                <br/>本保險契約用詞定義如下：
                                <br/>一、要保人：係指向本公司洽訂本保險契約有繳付保險費 義務之人，但不得為電信經銷商、行動裝置經銷商或維修商。
                                <br/>二、被保險人：係指於保險事故發生時，遭受損害，享有賠償請求權之人。要保人即為列名被保險人，經要保人許可使用承保行動裝置之人亦為被保險人。
                                <br/>三、行動裝置：係指要保人向本公司所約定之電信業者或通路商購買全新或經檢測通過之行動電話或平板電腦；經指定維修中心 原機維修或置換後，以原機維修或置換後之電子產品作為承保之行動裝置。
                                <br/>四、原機維修：係指本公司指定之維修廠商依照原廠授權之維修方式就行動裝置更換受損之零件，其他未受損之零件則不予更換。
                                <br/>五、置換：係指本公司依照原廠授權之方式以整新機置換行動裝置，若原廠未能提供整新機時，本公司以相同款式之新品行動裝置置換之。若同款行動裝置停產、缺貨或其他原因致本公司無法取得相同之行動裝置時，本公司有權以類似規格之新品行動裝置替代之。
                                <br/>六、產品識別碼：係指承保行動裝置之本體所載之IMEI碼或 S/N 序號。
                                <br/>七、指定維修中心：係指本保險契約所載明之電信業者、通路商門市或維修廠商 限 臺 澎金馬地區 。
                            </p>
                            <p style={{ color: "red" }}>
                                <b>第三條 承保範圍</b>
                                <br/>對於承保之行動裝置於保險期間內因不可預料之事故所致毀損，被保險人至遲應於本保險契約有效期間屆滿後十五個日曆天內將前述行動裝置送至指定維修中心者，本公司得選擇以原機維修或置換方式進行理賠，理賠次數以本保險契約所載之累計最高賠償次數為限。
                            </p>
                            <p>
                                <b>第四條 保險期間</b>
                                <br/>本保險契約之保險期間以本保險契約所載之日時為準；所 載日時以中原標準時間為準。
                            </p>
                            <p style={{ color: "red" }}>
                                <b>第五條 不保事項</b>
                                <br/>本公司對於承保行動裝置直接或間接因下列事項所致之毀損或有下列狀況時，不負賠償責任：
                                <br/>一、要保人及被保險人故意行為。
                                <br/>二、要保人及被保險人從事犯罪或教唆犯罪或逃避合法逮捕之行為。
                                <br/>三、戰爭、類似戰爭不論宣戰與否 、外敵入侵、外敵行為、內戰、叛亂、革命、軍事反叛行為或恐怖主義行為。所謂恐怖主義行為，係指任何個人或團體，不論單獨或與任何組織、團體或政府機構共謀，運用武力、暴力、恐嚇、威脅或破壞等行為，以遂其政治、宗教、信仰、意識型態或其他類似意圖之目的，包括企圖推翻、脅迫或影響任何政府，或致使民眾或特定群眾處於 恐懼狀態。
                                <br/>四、罷工、暴動、民眾騷擾。
                                <br/>五、颱風、暴風、龍捲風、洪水、閃電、雷擊、地震、火山爆發、海嘯、土崩、岩崩、土石流、地陷等天然災變。
                                <br/>六、任何性質之附帶損失。所謂附帶損失，係指危險事故直接致財產損失之結果所造成之間接損失。
                                <br/>七、原子或核子能裝置所引起的爆炸、灼熱、輻射或污染。
                                <br/>八、一般使用狀況下之正常損耗，或原廠公告非其保固範圍內之現象，包括但不限於螢幕影像殘留、亮點、暗點、烙印、色偏等。所謂正常損耗，係指磨損、腐蝕、氧化、鏽垢、變質、自然耗損。
                                <br/>九、因電池蝕漏、電力不足或遽增或不適當電壓或電流。
                                <br/>十、承保行動裝置製造商或經銷商依法或依約應負保固或瑕疵擔保責任範圍內提供之修復服務或召回。
                                <br/>十一、資料遺失、軟體無法使用、軟體包括操作系統與任何儲存資料 使用、安裝或移除以及電腦病毒或具有危險性之程式碼所導致之故障、承保行動裝置周邊設備或新增硬體所造成之故障。
                                <br/>十二、傳輸線、觸控筆、電線、連接器、 SIM 卡、記憶卡、充電器、耳機、周邊配備或其他非基本功能所必要之選購配備之損壞。不影響機體正常功能使用之行動裝置外型、非功能性或裝飾性零組件之損壞 例如行動裝置外觀無觸控或其他功能之玻璃材質部位磨損 。外殼、電池、充電孔、耳機孔、卡槽之單獨故障或損壞。
                                <br/>十三、承保行動裝置未經原廠同意之改裝、動拆、替換零件、不當安裝、變更或未遵守原廠關於組裝、操作或保養之說明與指示。或使用未經原廠授權之電池、零件或配件所致之損害。
                                <br/>十四、承保行動裝置欠缺外殼、螢幕或機板、無法清楚辨識或其產品識別碼遭竄改。
                                <br/>十五、任何個人及企業，將行動裝置使用於營利商業目的，包括但不限於租借他人使用所致之損壞。
                                <br/>十六、一般定期保養、清潔、調整或校準，以及未發現任何毀損或無法正常使用之檢查費用。
                                <br/>十七、承保行動裝置之遺失或因竊盜、搶奪、強盜行為所致承保行動裝置之滅失。
                            </p>
                            <p style={{ color: "red" }}>
                                <b>第六條 告知義務</b>
                                <br/>訂立本保險契約時，要保人對於本公司之書面或投保網頁 詢問，應據實說明。要保人有為隱匿或遺漏不為說明，或為不實之說明，足以變更或減少本公司對於危險之估計者，本公司得解除本保險契約；其危險發生後亦同。但要保人證明危險之發生未基於其說明或未說明之事實時，不在此限。前項解除契約權，自本公司知有解除之原因後，經過一個月不行使而消滅；或本保險契約訂立後經過二年，即有可以解除之原因，亦不得解除本保險契約。
                            </p>
                            <p>
                                <b>第七條 保險費之交付</b>
                                <br/>要保人於交付保險費時，本公司應給與收據或繳款證明或委由代收機構出具其它相關之繳款證明為憑。除經本公司同意延緩交付外，對於保險費交付前所發生之原機維修或置換，本公司不負賠償責任。
                            </p>
                            <p style={{ color: "red" }}>
                                <b>第八條 契約內容之變更</b>
                                <br/>本保險契約之變更申請，除另有約定外得以電話或書面為之，惟經本公司同意後始生效力。
                            </p>
                            <p style={{ color: "red" }}>
                                <b>第九條 保險契約終止與保險費返還</b>
                                <br/>本保險契約依下列情形終止時，除另有約定外，本公司就 終止前已繳付之未到期保險費，應按日數比例計算無息退還予要保人：
                                <br/>一、要保人申請終止本保險契約時，本保險契約自申請日之翌日零時起終止。
                                <br/>二、本公司終止本保險契約時，於終止日前十五日通知要保人後終止之。
                                <br/>三、若本公司已依本保險契約約定進行理賠後，已繳付之未到期保險費不予退還。本公司依本保險契約約定所負擔之理賠次數已達累計最高賠償次數時，本保險契約自本公司賠付後即行終止，本公司不另以書面通知終止，且已繳付之未到期保險費不予退還。
                            </p>
                            <p style={{ color: "red" }}>
                                <b>第十條 保險事故之發生、理賠申請與履行義務</b>
                                <br/>行動裝置發生本保險契約約定之承保事故時，被保險人應於本保險契約有效期間內，將受損之行動裝置送至指定維修中心，並透過指定維修中心提供下列資料向本公司申請理賠：
                                <br/>一、理賠申請書或經本公司同意以其他方式為之。
                                <br/>二、必要時本公司得要求檢附其他證明文件。被保險人申請理賠時應按下列約定辦理:
                                <br/>一、應先取消或停止行動裝置安全密碼設定，或其他須經授權存取之安全措施。
                                <br/>二、應自行於送修前完成所需之備份作業，本公司不負責行動裝置內附軟體及資料、照片之回復、歸還或保存。
                            </p>
                            <p style={{ color: "red" }}>
                                <b>第十一條 理賠方式</b>
                                <br/>行動裝置發生本保險契約約定之承保事故，本公司以原機維修為原則，若無法原機維修或維修費用高於置換費用時，以置換方式進行理賠。本公司以置換方式進行理賠後，即取得原送修之行動裝置之所有權。
                            </p>
                            <p style={{ color: "red" }}>
                                <b>第十二條 自負額</b>
                                <br/>被保險人對於保險期間內每一保險事故，須先負擔本保險契約所約定之自負額，本公司僅對超過自負額之部分負賠償之責。
                            </p>
                            <p style={{ color: "red" }}>
                                <b>第十三條 代位</b>
                                <br/>被保險人因本保險契約承保範圍內之損失而對於第三人有賠償請求 權者，本公司得於給付賠償金額後，於賠償金額範圍內代位行使被保險人對於第三人之請求權，所衍生之費用由本公司負擔。倘該第三人為行動裝置製造商，本公司拋棄代位求償權。
                                <br/><br/>被保險人不得免除或減輕對第三人之請求權利或為任何不利本公司行使該項權利之行為，被保險人違反前述約定者，雖理賠金額已給付，本公司仍得於受妨害而未能請求之範圍內請求被保險人返還之。
                            </p>
                            <p>
                                <b>第十四條 申訴、調解或仲裁</b>
                                <br/>本公司與要保人或被保險人或其他有保險賠償請求權之人對於因本保險契約所生爭議時，得提出申訴或提交調解 或經雙方同意提交仲裁，其程序及費用等，依相關法令或仲裁法規定辦理。
                            </p>
                            <p style={{ color: "red" }}>
                                <b>第十五條 管轄法院</b>
                                <br/>因本保險契約涉訟時，約定以要保人或被保險人住所地之地方法院為管轄法院。但要保人或被保險人住所地在中華民國境外者，則以臺灣臺北地方法院為管轄法院。
                            </p>
                            <p style={{ color: "red" }}>
                                <b>第十六條 法令適用</b>
                                <br/>本保險契約未約定之其他事項，悉依照中華民國保險法及有關法令之規定辦理。
                            </p>
                        </div>

                    </div>

                </div>

            </div>
        </AppLayout>
    );
};

export default PageTermsAndConditions08Lite;
